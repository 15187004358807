@charset "utf-8";

@import "../base/variables";
@import "../base/mixins";
@import "../base/functions";



//
// hero header
//
.front-page__hero {
  .z-1 {
    z-index: 1;
  }
  .z-1-2 {
    z-index: 3;
  }
}

.hero__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("../image/frontpage/img-top.jpg");
  background-size: cover;
  background-position: top center;
  background-attachment: fixed;
  height: calc(100vw * 0.666666);
  @include to-tablet {
    height: calc(100vh - 50px);
    background-image: url("../image/frontpage/img-top_sp.jpg");
    background-attachment: inherit;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(39, 52, 90, 0.2);
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  @include from-desktop {
    .hero__inner {
      background-image: url("../image/frontpage/img-top@2x.jpg");
    }
  }
}

.hero__announce {
  display: flex;
  position: absolute;
  top: 110px;
  left: 0;
  right: 0;
  height: 40px;
  @include to-tablet {
    top: 25px;
    flex-direction: column;
    height: auto;
  }
  &__title {
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: $general-navy;
    color: $white;
    font-size: 1.6rem;
    font-weight: 600;
    @include to-tablet {
      padding: 5px 20px;
      justify-content: center;
      font-size: 1.4rem;
    }
  }
  &__body {
    display: flex;
    align-items: center;
    flex-grow: 6;
    padding: 0 20px;
    background-color: $white;
    @include to-tablet {
      padding: 5px 10px;
      font-size: 1.2rem;
    }
  }
}

.hero__announce.is-hidden {
  display: none;
}

.hero__caption {
  color: $white;
  text-align: center;
  letter-spacing: 0.1em;
  &__en {
    font-size: 5.0rem;
    font-family: $font-en;
    font-weight: 500;
    line-height: 1.2;
    @include to-tablet {
      font-size: 7.8125vw;
    }
  }
  &__ja {
    font-size: 3.0rem;
    font-family: $font-title-jp;
    @include to-tablet {
      font-size: 4.6875vw;
    }
  }
}

//
// 事業内容
//
.front-page__business {
  .box--num__desc {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
  }
}

.business__detail__bg {
  position: absolute;
  top: 5vw;
  width: 61.857142857vw;
  height: 32.928571428vw;
  max-width: 950px;
  max-height: 400px;
  @include to-tablet {
    display: none;
  }
  &.is-right {
    left: 24.357142857vw;
  }
  &.is-left {
    right: 24.357142857vw;
  }
}

.business__detail__button {
  margin-top: 15px;
}

.front-page__business__map {
  margin-top: 300px;
  @include to-tablet {
    margin-top: 100px;
  }
  p {
    text-align: center;
    font-family: $font-title-jp;
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: 0.05em;
    @include to-tablet {
      font-size: 1.5rem;
    }
  }
}

.business--map {
  position: relative;
  margin: 100px auto 0;
  width: 73.928571428vw;
  max-width: 1200px;
  @include to-tablet {
    margin: 40px auto 0;
    width: 100%;
  }
  svg {
    width: 1200px;
    height: 957px;
    max-height: 100%;
    max-width: 100%;
    @include to-tablet {
      width: 100%;
      height: 100%;
    }
  }
}

.business__map__button {
  margin-top: 140px;
  @include to-tablet {
    margin-top: 50px;
  }
}

//
// 地図SVG＆アニメーション
//
.map__circle {
  display: inline-block;
  animation: circleAnime 1.5s cubic-bezier(.78,.03,.62,.97);
  animation-iteration-count: infinite;
  &-mie {
    width: 100px;
    height: 100px;
    transform-origin: 573.5px 809px 0;
  }
  &-shizuoka {
    width: 100px;
    height: 100px;
    transform-origin: 670.5px 830px 0;
  }
  &-aichi {
    width: 120px;
    height: 120px;
    transform-origin: 631.5px 787px 0;
  }
  &-gifu {
    width: 100px;
    height: 100px;
    transform-origin: 631.5px 743px 0;
  }
  &-fukui {
    width: 120px;
    height: 120px;
    transform-origin: 612.5px 701px 0;
  }
}

@keyframes circleAnime {
  0% {
    transform: scale(0.1, 0.1);
  }
  80% {
    opacity: 0.4;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
}


//
// 実績・実例
//
.works--slider .swiper-container {
  padding-top: 40px;
}

.works--slider .swiper-button-prev {
  top: 0;
  left: 0;
  width: 40px;
  height: 7px;
  margin-top: 0;
  background-image: url(../../../assets/image/common/arrow-left-n.svg);
  color: transparent;
  &:after {
    display: none;
  }
}

.works--slider .swiper-button-next {
  top: 0;
  right: 0;
  width: 40px;
  height: 7px;
  margin-top: 0;
  background-image: url(../../../assets/image/common/arrow-right-n.svg);
  color: transparent;
  &:after {
    display: none;
  }
}

.works--slider .swiper-pagination {
  position: relative;
  margin-top: 50px;
  @include to-tablet {
    margin-top: 25px;
  }
}

.works--slider__title {
  padding: 30px 0;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  @include to-tablet {
    font-size: 1.5rem;
  }
}

.works--slider .swiper-pagination-bullet {
  margin: 0 10px!important;
  width: 12px;
  height: 12px;
  background-color: $light-horizon-blue;
  opacity: 1;
}

.works--slider .swiper-pagination-bullet-active {
  background-color: $horizon-blue;
}


  //
// 当社の強み
//

.front-page__feature {
  @include to-tablet {
    padding-bottom: 0;
  }
}

.feature__link.box--big-banner {
  margin-top: 100px;
  @include to-tablet {
    margin-top: 30px;
  }
}

.feature__link .big-banner__image {
  background-image: url("../image/frontpage/img-feature-top.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  @include to-tablet {
    background-attachment: inherit;
  }

  .catch {
    margin-bottom: 20px;
    font-size: 3.0rem;
    font-family: $font-en;
    font-weight: 500;
    letter-spacing: 0.1em;
    @include to-tablet {
      margin-bottom: 10px;
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .title {
    margin-bottom: 80px;
    font-family: $font-title-jp;
    font-size: 5.0rem;
    font-weight: 300;
    letter-spacing: 0.1em;
    @include to-tablet {
      margin-bottom: 20px;
      font-size: 2.5rem;
      font-weight: 400;
      text-align: center;
    }
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2){
  .feature__link .big-banner__image {
    background-image: url("../image/frontpage/img-feature-top@2x.jpg");
  }
}

.feature__link .big-banner__image__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include to-tablet {
    align-items: stretch;
  }
}

.feature__link__button {
  @include to-tablet {
    margin-left: auto;
    margin-right: auto;
    width: 61.5384615385vw;
  }
}


//
// 主な取引先企業
//
.client__list {
  margin-left: auto;
  margin-right: auto;
  width: 86.6666666667%;
}

.front-page__client .client__list__title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-page__client .client__list__info {
  font-size: 2.0rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  text-align: center;
  @include to-tablet {
    font-size: 1.3rem;
  }
}


//
// お知らせ
//
.front-page__topics__inner {
  @include to-tablet {
    margin-left: auto;
    margin-right: auto;
    width: 86.6666666667%;
  }
}

.front-page__topics .topics__title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.front-page__topics .topics__body {
  padding-right: 30px;
  @include to-tablet {
    padding-right: 0;
  }
}