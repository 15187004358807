@mixin is-mobile {
  @media screen and (max-width: $min-width-tablet - 1) {
    @content;
  }
}

@mixin from-tablet {
  @media screen and (min-width: $min-width-tablet) {
    @content;
  }
}

@mixin is-tablet {
  @media screen and (min-width: $min-width-tablet) and (max-width: $min-width-desktop - 1) {
    @content;
  }
}

@mixin to-tablet {
  @media screen and (max-width: $min-width-desktop - 1) {
    @content;
  }
}

@mixin from-desktop {
  @media screen and (min-width: $min-width-desktop) {
    @content;
  }
}

@mixin is-desktop {
  @media screen and (min-width: $min-width-desktop) and (max-width: $min-width-widescreen - 1) {
    @content;
  }
}

@mixin is-widescreen {
  @media screen and (min-width: $min-width-widescreen) {
    @content;
  }
}

@mixin is-hover {
  @media (-ms-high-contrast: none) {
    @content;
  }

  @media (hover: hover) {
    @content;
  }
}

@mixin is-2x {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content;
  }
}

@mixin clearfix {
  &:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}
