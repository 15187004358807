$min-width-tablet: 550px;
$min-width-desktop: 960px;
$min-width-widescreen: 1200px;

$content-min-width-mobile: 320px;
$content-max-width-mobile: ($min-width-tablet - 1px);
$content-min-width-tablet: 550px;
$content-max-width-tablet: 960px;
$content-min-width-desktop: 960px;
$content-max-width-desktop: none;

$content-padding-horizontal-mobile: 5vw;
$content-padding-horizontal-tablet: 6vw;
$content-padding-horizontal-desktop: 6vw;
$content-padding-horizontal-widescreen: 6vw;

$design-width-mobile: 750px;
$width-mobile: 375px;

$header-height: 80px;

//
// コンテナサイズ
//
$container-max-size-large: 1200px;
$container-max-size-medium: 1057px;
$container-max-size-small: 960px;

//
// フォント
//
$font-sans-serif: -apple-system, BlinkMacSystemFont, Roboto, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "Noto Sans JP", "ＭＳ Ｐゴシック", Osaka, sans-serif;
$font-serif: serif;
$font-thin: 'Abel', sans-serif;
$font-title-jp: 'Noto Sans JP', sans-serif;
$font-en: 'Roboto', sans-serif;

//
// color
//
$general-navy: #27345A;
$dark-navy: #273039;
$dull-navy: #2F374E;
$white: #fff;
$horizon-blue: #6AAEC2;
$light-horizon-blue: #ACCECC;
$blue-gray: #A4B5BF;
$pale-blue-gray: #F7FAFA;
$light-blue-gray: #E2E6E6;
$pale-gray: #EBEBEB;
$light-gray: #BEBEBE;
$medium-gray: #727272;